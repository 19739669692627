import React, { useState } from "react";
import { Box, Paper, TextField } from "@mui/material";

const Console = ({ output, waitingForInput, onInputSubmit }) => {
  const [currentInput, setCurrentInput] = useState("");

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && currentInput.trim()) {
      onInputSubmit(currentInput); // 提交输入
      setCurrentInput(""); // 清空输入框
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        mt: 2,
        maxHeight: "200px", // 高度调整为原来的1.5倍
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        bgcolor: "#ffffff", // 白色背景
        border: "1px solid #d1d5db", // 中性灰色边框
        borderRadius: "0px", // 无圆角
      }}
    >
      {/* 输出区域 */}
      <Box
        sx={{
          flex: 1,
          fontSize: "1.125rem", // 字体略微增大
          letterSpacing: "0.05em", // 增大字符间距
          color: "#111827", // 深灰文本颜色
          overflowY: "auto",
          whiteSpace: "pre-wrap",
        }}
      >
        {output || "暂无输出"} {/* 显示当前输出或占位符 */}
      </Box>

      {/* 输入区域 */}
      {waitingForInput && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 2,
            pt: 2,
            borderTop: "1px solid #e5e7eb", // 浅灰分隔线
          }}
        >
          <Box
            sx={{
              fontSize: "1.125rem", // 与输出字体一致
              fontWeight: "400",
              color: "#374151", // 中灰色文本
              mr: 1,
              letterSpacing: "0.05em", // 保持输入区域字符间距一致
            }}
          >
            {">"} {/* 输入提示符 */}
          </Box>
          <TextField
            variant="standard"
            InputProps={{
              style: {
                color: "#374151",
                letterSpacing: "0.05em", // 字符间距
              },
            }}
            fullWidth
            value={currentInput}
            onChange={(e) => setCurrentInput(e.target.value)}
            onKeyDown={handleKeyDown}
            sx={{
              "& .MuiInput-underline:before": { borderBottomColor: "#d1d5db" },
              "& .MuiInput-underline:after": { borderBottomColor: "#4b5563" }, // 深灰高亮色
            }}
          />
        </Box>
      )}
    </Paper>
  );
};

export default Console;
