// QuestionSwitcher.jsx
import React, { useState, useMemo } from "react";
import {
  Typography,
  Button,
  Paper,
  Box,
  Chip,
  Snackbar,
  Alert,
} from "@mui/material";
import QuestionItem from "./QuestionItem"; // 引入独立的 QuestionItem 组件

const QuestionSwitcher = ({ AllQuestions, setSelectedQuestion }) => {
  const typeLabels = {
    1: "简单题",
    2: "中等题",
    3: "高级题",
  };

  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(0); // 当前页数
  const itemsPerPage = 10; // 每页显示的题目数

  // 切换题目类型
  const handleTypeClick = (type) => {
    let newSelectedTypes;
    if (selectedTypes.includes(type)) {
      // 如果已经选中则取消选中
      newSelectedTypes = selectedTypes.filter((t) => t !== type);
    } else {
      // 如果未选中则添加到已选类型
      newSelectedTypes = [...selectedTypes, type];
    }
    setSelectedTypes(newSelectedTypes);
    setCurrentPage(0); // 重置分页到第一页
  };

  // 使用 useMemo 优化计算
  const filteredQuestions = useMemo(() => {
    return selectedTypes.length === 0
      ? AllQuestions // 没有选中类型时，展示所有题目
      : AllQuestions.filter((question) =>
        selectedTypes.includes(question.type.toString())
      );
  }, [AllQuestions, selectedTypes]);

  const totalPages = useMemo(() => {
    return Math.ceil(filteredQuestions.length / itemsPerPage);
  }, [filteredQuestions.length]);

  const paginatedQuestions = useMemo(() => {
    return filteredQuestions.slice(
      currentPage * itemsPerPage,
      (currentPage + 1) * itemsPerPage
    );
  }, [filteredQuestions, currentPage]);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleQuestionClick = (question) => {
    setSelectedQuestion(question);
    setSelectedQuestionId(question.id);
    setSnackbarMessage(`已选择: ${question.name}`);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Paper elevation={3} sx={{
      p: 2,
      width: "100%", // 确保组件占满父容器宽度
      height: "100%", // 占满父容器高度
      display: "flex",
      flexDirection: "column",
      boxSizing: "border-box", // 确保内边距不影响宽度
      overflowY: "auto", // 垂直滚动支持
      borderRadius: "0px", // 无圆角
    }}>
      {/* 类型选择器 */}
      <Box
        sx={{ mb: 2, display: "flex", alignItems: "center", flexWrap: "wrap", gap: 1, display: "flex", }}
      >
        {Object.keys(typeLabels).map((type) => (
          <Chip
            key={type}
            label={typeLabels[type]}
            onClick={() => handleTypeClick(type)}
            color={selectedTypes.includes(type) ? "primary" : "default"}
            sx={{ m: 0.5, cursor: "pointer" }}
          />
        ))}
      </Box>

      {/* 分页按钮 */}
      {totalPages > 1 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
            alignItems: "center",
          }}
        >
          <Button onClick={handlePreviousPage} disabled={currentPage === 0}>
            上一页
          </Button>
          <Typography variant="body2">
            第 {currentPage + 1} 页，共 {totalPages} 页
          </Typography>
          <Button
            onClick={handleNextPage}
            disabled={currentPage === totalPages - 1}
          >
            下一页
          </Button>
        </Box>
      )}

      {/* 题目展示 */}
      <Box sx={{
        flex: 1, // 占据剩余高度
      }}>
        {paginatedQuestions.map((question) => (
          <QuestionItem
            key={`question-${question.id}`}
            question={question}
            isSelected={selectedQuestionId === question.id}
            onSelect={handleQuestionClick}
          />
        ))}
      </Box>

      {/* Snackbar 提示 */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default QuestionSwitcher;
