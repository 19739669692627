import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  Container,
  Typography,
  Paper,
  Box,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Link,
} from "@mui/material";
import axios from "axios";

const HelpPage = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // 新增错误信息

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleResendEmail = async () => {
    setSuccessMessage("");
    setErrorMessage(""); // 清除之前的错误或成功信息

    if (!validateEmail(email)) {
      setErrorMessage("请输入有效的邮箱地址");
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post("/auth/resend-email", { email });

      if (response.status === 200) {
        setSuccessMessage(response.data.message);
      } else {
        setErrorMessage(response.data.message || "发生未知错误");
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("网络错误，请稍后重试");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Helmet>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Python二级在线帮助中心，解决常见问题，包括邮箱问题、登录问题、续费问题等。"
        />
        <meta name="keywords" content="Python二级, 帮助中心, 邮箱问题, 登录问题, 续费问题, turtle绘图" />
        <meta name="author" content="Python2ji Team" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Meta Tags for social sharing */}
        <meta property="og:title" content="Python二级帮助中心" />
        <meta
          property="og:description"
          content="常见问题解答，帮助您解决Python二级相关的疑问，邮箱、登录、续费等问题"
        />
        <meta property="og:image" content="https://python2ji.com/logo.png" />

        <title>常见问题解答 | Python二级在线</title>
      </Helmet>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
          帮助中心
        </Typography>

        {/* 目录部分 */}
        <Typography variant="h5" gutterBottom>
          目录
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem", mb: 2 }}>
          <Link href="#q1">1. 为什么没有收到邮件？</Link>
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem", mb: 2 }}>
          <Link href="#q2">2. 已购买高级版，怎么登录？</Link>
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem", mb: 2 }}>
          <Link href="#q3">3. 如何找回私有登录地址？</Link>
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem", mb: 2 }}>
          <Link href="#q4">4. 为什么错误的代码也可以通过？</Link>
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem", mb: 2 }}>
          <Link href="#q5">5. 可以在到期前提前进行续费吗？</Link>
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem", mb: 2 }}>
          <Link href="#q6">6. 怎么知道自己剩余的使用时间？</Link>
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem", mb: 4 }}>
          <Link href="#q7">7.为什么turtle相关代码运行时候无法正确画图？</Link>
        </Typography>

        <Box component="form" sx={{ mb: 4 }}>
          <TextField
            label="邮箱地址"
            variant="outlined"
            fullWidth
            value={email}
            onChange={handleEmailChange}
            sx={{ mb: 2 }}
          />

          {errorMessage && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {errorMessage}
            </Alert>
          )}

          {successMessage && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {successMessage}
            </Alert>
          )}

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleResendEmail}
            disabled={isLoading}
            sx={{ padding: "12px", fontSize: "16px" }}
          >
            {isLoading ? <CircularProgress size={24} color="inherit" /> : "重新发送登录链接"}
          </Button>
        </Box>

        {/* 问题部分 */}
        <Typography id="q1" variant="h5" gutterBottom sx={{ mb: 3, mt: 4 }}>
          1. 为什么没有收到邮件？
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem" }} gutterBottom>
          有几种情况可能导致您未收到邮件：
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem" }} gutterBottom>
          1. 邮件被误认作广告，请查看垃圾文件夹。
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem" }} gutterBottom>
          2. 您可能误删邮件，可以在当前页面重新发送。
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem" }} gutterBottom>
          3. 您输入了错误的邮箱地址，请通过邮件或QQ联系客服，提供支付记录中的交易单号。
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem" }} gutterBottom>
          4. 邮箱服务商拒绝了邮件，请尝试使用其他邮箱与我们联系。
        </Typography>

        <Typography id="q2" variant="h5" gutterBottom sx={{ mb: 3, mt: 4 }}>
          2. 已购买高级版，怎么登录？
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem" }} gutterBottom>
          方法一（推荐）：
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem" }} gutterBottom>
          1. 打开购买时填写的邮箱，搜索关键字“python二级”找到邮件（可能在垃圾文件夹）。
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem" }} gutterBottom>
          2. 复制登录地址并在浏览器中打开即可登录。
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem" }} gutterBottom>
          方法二：
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem" }} gutterBottom>
          将支付宝支付记录中的交易单号发送到下方邮箱，我们会尽快联系您。
        </Typography>
        <Typography variant="body2">
          邮箱地址：kefu@python2ji.com
          或者联系客服qq:3929316933
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem" }} gutterBottom>
          客服工作时间：9:30 - 18:00
        </Typography>

        <Typography id="q3" variant="h5" gutterBottom sx={{ mb: 3, mt: 4 }}>
          3. 如何找回私有登录地址？
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem" }} gutterBottom>
          完整私有登录地址格式为：http://python2ji/login?uid=xxxxx...xxxxx
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem" }} gutterBottom>
          方法一（推荐）：
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem" }} gutterBottom>
          打开购买时填写的邮箱，搜索关键字“python二级在线”，找到邮件并复制登录地址。
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem" }} gutterBottom>
          方法二（推荐）：
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem" }} gutterBottom>
          在当前页面填写邮箱，重新发送链接。如果输入错误的邮箱地址，请提供支付记录中的交易单号并联系客服。
        </Typography>
        <Typography variant="body2">
          邮箱地址：kefu@python2ji.com
          或者联系客服qq:3929316933
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem" }} gutterBottom>
          客服工作时间：9:30 - 18:00
        </Typography>

        {/* 新增问题和回答 */}
        <Typography id="q4" variant="h5" gutterBottom sx={{ mb: 3, mt: 4 }}>
          4. 为什么错误的代码也可以通过？
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem" }} gutterBottom>
          因为我们在测试中使用了样例测试数据，错误的代码在特定样例上可能表现正确，所以请不要面向结果编程哦。
        </Typography>

        <Typography id="q5" variant="h5" gutterBottom sx={{ mb: 3, mt: 4 }}>
          5. 可以在到期前提前进行续费吗？
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem" }} gutterBottom>
          可以，提前续费的时间将自动累加到剩余使用时间中，不会浪费任何时间。
        </Typography>

        <Typography id="q6" variant="h5" gutterBottom sx={{ mb: 3, mt: 4 }}>
          6. 怎么知道自己剩余的使用时间？
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem" }} gutterBottom>
          在您购买后发送的确认邮件中包含了剩余使用时间的信息。如果邮件遗失，您可以使用当前页面的重新发送功能重新获取相关信息。
        </Typography>
        <Typography id="q7" variant="h5" gutterBottom sx={{ mb: 3, mt: 4 }}>
          7. 为什么turtle相关代码运行时候无法正确画图？
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "1rem" }} gutterBottom>
          请确保代码最后以 turtle.done() 或 t.done() 结尾来让绘图窗口保持打开状态
        </Typography>
      </Paper>
    </Container>
  );
};

export default HelpPage;
