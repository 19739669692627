import React, { useEffect } from "react";
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const MultipleChoiceQuestion = ({
  displayedQuestions,
  currentQuestionIndex,
  userAnswers,
  handleAnswer,
  handleNext,
  handlePrevious,
  setShowPurchaseDialog, // 从父组件传递的购买提示
}) => {
  const questionsPerPage = 1;

  // 当前题目
  const startIndex = currentQuestionIndex;
  const endIndex = Math.min(
    startIndex + questionsPerPage,
    displayedQuestions.length
  );
  const currentQuestions = displayedQuestions.slice(startIndex, endIndex);

  // 键盘事件监听
  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case "ArrowRight":
          handleNext();
          break;
        case "ArrowLeft":
          handlePrevious();
          break;
        case "1":
        case "2":
        case "3":
        case "4":
          if (currentQuestions[0]?.is_locked) {
            setShowPurchaseDialog(true); // 弹出购买提示
            return;
          }
          handleAnswer(currentQuestionIndex, parseInt(event.key) - 1);
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    currentQuestionIndex,
    handleNext,
    handlePrevious,
    handleAnswer,
    setShowPurchaseDialog,
    currentQuestions,
  ]);

  // 如果题目被锁定
  if (currentQuestions[0]?.is_locked) {
    return (
      <Typography
        variant="h6"
        sx={{ textAlign: "center", mt: 4, color: "gray" }}
      >
        此题已锁定。成为会员可以解锁更多内容！
      </Typography>
    );
  }

  // 如果没有题目
  if (currentQuestions.length === 0) {
    return (
      <Typography variant="h6" sx={{ textAlign: "center", mt: 4 }}>
        没有符合条件的题目。
      </Typography>
    );
  }

  const question = currentQuestions[0]; // 当前题目
  const userAnswer = userAnswers[currentQuestionIndex];
  const isCorrect = userAnswer === question.answer;

  return (
    <Box>
      <Box sx={{ mb: 4 }}>
        {/* 题目内容 */}
        <Typography variant="h5" gutterBottom>
          {`${currentQuestionIndex + 1}. ${question.question}`}
        </Typography>

        {/* 选项 */}
        <RadioGroup
          value={userAnswer !== null ? userAnswer : ""}
          onChange={(e) =>
            handleAnswer(currentQuestionIndex, parseInt(e.target.value))
          }
        >
          {question.options.map((option, optionIndex) => (
            <FormControlLabel
              key={optionIndex}
              value={optionIndex}
              control={<Radio />}
              label={
                <Typography sx={{ fontSize: "1.2rem" }}>{option}</Typography>
              }
            />
          ))}
        </RadioGroup>

        {/* 答案反馈 */}
        {userAnswer !== null && (
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            {isCorrect ? (
              <CheckCircleIcon sx={{ color: "green", mr: 1 }} />
            ) : (
              <CancelIcon sx={{ color: "red", mr: 1 }} />
            )}
            <Typography
              variant="body1"
              sx={{
                fontSize: "1.1rem",
                lineHeight: "2",
                fontWeight: "400",
              }}
            >
              {isCorrect ? "正确" : "错误"} - {question.explanation}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MultipleChoiceQuestion;
