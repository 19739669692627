import React from "react";
import { Box, Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import BugReportIcon from "@mui/icons-material/BugReport";

const SubmissionSection = ({
  handleSubmit,
  handleDebug,
  handleShowAnswer,
  isLoading,
}) => {
  return (
    <Box
      sx={{
        mt: 2,
        display: "flex",
        justifyContent: "space-between", // 左右对齐
        flexDirection: { xs: "column", sm: "row" },
        gap: 2, // 按钮间的统一间距
      }}
    >
      {/* “显示答案”按钮固定放在左侧 */}
      <Box>
        <Button
          variant="contained"
          color="warning"
          onClick={handleShowAnswer}
          startIcon={<VisibilityIcon />}
        >
          显示答案
        </Button>
      </Box>

      {/* 调试和提交按钮放在右侧 */}
      <Box sx={{ display: "flex", gap: 2 }}>
        {/* 调试按钮：灰色背景，白色文字 */}
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#757575", // 灰色背景
            color: "#FFFFFF", // 白色文字
            "&:hover": {
              backgroundColor: "#616161", // Hover时更深的灰色
            },
          }}
          onClick={handleDebug}
          startIcon={<BugReportIcon />}
          disabled={isLoading}
        >
          调试
        </Button>

        {/* 提交按钮 */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          startIcon={<PlayArrowIcon />}
          disabled={isLoading}
        >
          提交
        </Button>
      </Box>
    </Box>
  );
};

export default SubmissionSection;
