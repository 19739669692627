import React, { useEffect, useState } from "react";
import { Box, Typography, Button, useTheme } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const MultipleChoiceSwitcher = ({
  displayedQuestions = [],
  userAnswers = [],
  setCurrentQuestionIndex,
  currentPage = 0,
  setCurrentPage,
  setShowPurchaseDialog,
  currentQuestionIndex, // 新增，用于监听题目变化
}) => {
  const questionsPerPage = 25;

  const theme = useTheme(); // 获取当前主题
  const isDarkMode = theme.palette.mode === "dark"; // 判断是否是暗模式

  const [manualPageChange, setManualPageChange] = useState(false); // 新增状态，追踪手动分页

  // 总页数，确保 displayedQuestions 不为空
  const totalPages = Math.ceil((displayedQuestions?.length || 0) / questionsPerPage);

  // 当前页的起始和结束索引
  const startIndex = currentPage * questionsPerPage;
  const endIndex = Math.min(
    startIndex + questionsPerPage,
    displayedQuestions.length
  );
  const currentQuestions = displayedQuestions.slice(startIndex, endIndex) || [];

  // 点击题目时的逻辑
  const handleQuestionClick = (localIndex) => {
    const globalIndex = localIndex + currentPage * questionsPerPage; // 基于当前分页计算全局索引

    if (displayedQuestions[globalIndex]?.is_locked) {
      setShowPurchaseDialog(true);
      return;
    }

    setCurrentQuestionIndex(globalIndex); // 更新当前题目索引
    setManualPageChange(false); // 确保题目选择后同步分页
  };

  // 监听 currentQuestionIndex 的变化并同步页码（除非是手动分页）
  useEffect(() => {
    if (!manualPageChange) {
      const newPage = Math.floor(currentQuestionIndex / questionsPerPage);
      if (newPage !== currentPage) {
        setCurrentPage(newPage); // 动态更新页码
      }
    }
  }, [currentQuestionIndex, currentPage, setCurrentPage, manualPageChange, questionsPerPage]);

  // 点击分页按钮时切换页码，并标记为手动分页
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setManualPageChange(true); // 标记为手动分页
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {/* 标题和分页按钮 */}
      <Box sx={{ mb: 2 }}>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={() => handlePageChange(Math.max(currentPage - 1, 0))}
            disabled={currentPage === 0}
            variant="contained"
            color="primary"
          >
            上一组
          </Button>
          <Button
            onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages - 1))}
            disabled={currentPage >= totalPages - 1}
            variant="contained"
            color="secondary"
          >
            下一组
          </Button>
        </Box>
      </Box>

      {/* 题目列表 */}
      <Box
        sx={{
          flex: 1,
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)", // 5列布局
          gap: 1, // 列和行间距
        }}
      >
        {currentQuestions.length > 0 ? (
          currentQuestions.map((question, index) => {
            const globalIndex = displayedQuestions.indexOf(question);
            const answerStatus =
              userAnswers[globalIndex] === null
                ? "unfinished"
                : userAnswers[globalIndex] === question.answer
                  ? "correct"
                  : "incorrect";
            const isLocked = displayedQuestions[globalIndex]?.is_locked;


            // 动态设置背景色和字体颜色
            const backgroundColor =
              answerStatus === "unfinished"
                ? isDarkMode
                  ? "#424242"
                  : "#ffffff"
                : answerStatus === "correct"
                  ? isDarkMode
                    ? "#1b5e20"
                    : "#4caf50"
                  : isDarkMode
                    ? "#b71c1c"
                    : "#f44336";

            const textColor =
              answerStatus === "unfinished"
                ? isDarkMode
                  ? "#ffffff"
                  : "#000000"
                : "#ffffff";

            return (
              <Box
                key={index}
                onClick={() => handleQuestionClick(index)}
                sx={{
                  textAlign: "center",
                  border: globalIndex === currentQuestionIndex ? "2px solid #ffa726" : "1px solid #ccc",
                  boxSizing: "border-box",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50px",
                  width: "50px",
                  borderRadius: "4px",
                  backgroundColor: isLocked ? "#ffccbc" : backgroundColor,
                  color: textColor,
                  cursor: isLocked ? "not-allowed" : "pointer",
                  transition: "background-color 0.3s ease, border-color 0.3s ease",
                  "&:hover": {
                    backgroundColor: isLocked ? "#ffccbc" : "#e3f2fd",
                  },
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontSize: "0.875rem", fontWeight: "bold" }}
                >
                  {startIndex + index + 1}
                </Typography>

                {isLocked && (
                  <LockOutlinedIcon
                    sx={{
                      position: "absolute",
                      top: "3px",
                      right: "3px",
                      fontSize: "0.875rem",
                      color: "#FF5722",
                      opacity: 0.7,
                    }}
                  />
                )}
              </Box>
            );
          })
        ) : (
          <Typography
            variant="body2"
            sx={{
              color: "gray",
              textAlign: "center",
              mt: 2,
              lineHeight: 1.5, // 增加行高
              padding: "8px 0", // 增加上下内边距
              fontSize: "1rem",
            }}
          >
            正在加载...
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default MultipleChoiceSwitcher;
