import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";

const EditorSettingsModal = ({
  isOpen,
  onClose,
  onSave,
  initialSettings,
  theme,
}) => {
  const [localSettings, setLocalSettings] = useState(initialSettings);

  // 每次打开对话框时同步设置
  useEffect(() => {
    setLocalSettings(initialSettings);
  }, [initialSettings]);


  // 处理输入变化
  const handleChange = (field) => (event) => {
    setLocalSettings({
      ...localSettings,
      [field]:
        field === "fontSize"
          ? parseInt(event.target.value, 10)
          : event.target.value,
    });
  };

  // 保存设置
  const handleSave = () => {
    onSave(localSettings);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>编辑器设置</DialogTitle>
      <DialogContent>
        {/* 主题选择 */}
        <Typography variant="body1" gutterBottom>
          主题
        </Typography>
        <TextField
          select
          fullWidth
          value={localSettings.theme}
          onChange={handleChange("theme")}
        >
          <MenuItem value="monokai">Monokai</MenuItem>
          <MenuItem value="textmate">Textmate</MenuItem>
          <MenuItem value="github">GitHub</MenuItem>
        </TextField>

        {/* 字体大小 */}
        <Typography variant="body1" gutterBottom>
          字体大小
        </Typography>
        <TextField
          type="number"
          fullWidth
          value={localSettings.fontSize}
          onChange={handleChange("fontSize")}
        />

        {/* 缩进大小 */}
        <Typography variant="body1" gutterBottom>
          缩进大小
        </Typography>
        <TextField
          type="number"
          fullWidth
          value={localSettings.indentSize}
          onChange={handleChange("indentSize")}
        />

        {/* 代码补全 */}
        <Typography variant="body1" gutterBottom>
          代码补全
        </Typography>
        <TextField
          select
          fullWidth
          value={localSettings.codeCompletion}
          onChange={handleChange("codeCompletion")}
        >
          <MenuItem value="单词级补全">单词级补全</MenuItem>
          <MenuItem value="语句级补全">语句级补全</MenuItem>
        </TextField>
      </DialogContent>
      
      {/* 对话框底部按钮 */}
      <DialogActions>
        <Button onClick={onClose}>取消</Button>
        <Button onClick={handleSave} color="primary">
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditorSettingsModal;
