import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Typography,
  Paper,
  Button,
  Alert,
  AlertTitle,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import MultipleChoiceQuestion from "../components/MultipleChoicePage/MultipleChoiceQuestion";
import MultipleChoiceSwitcher from "../components/MultipleChoicePage/MultipleChoiceSwitcher";
import TagFilter from "../components/MultipleChoicePage/TagFilter";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const MultipleChoicePage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // 控制提示的显示和隐藏，默认在移动端不显示提示
  const [showAlert, setShowAlert] = useState(!isMobile);
  const [showPurchaseDialog, setShowPurchaseDialog] = useState(false); // 用于控制购买提示框


  // 新增：初始化 questions 状态
  const [questions, setQuestions] = useState([]); // 用于存储解密后的问题数据

  // 用于跟踪当前用户的答案和答题状态
  const [userAnswers, setUserAnswers] = useState([]); // 初始化为空数组
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedTags, setSelectedTags] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [displayedQuestions, setDisplayedQuestions] = useState([]);
  useEffect(() => {
    if (currentQuestionIndex >= displayedQuestions.length) {
      setCurrentQuestionIndex(0); // 超出范围时重置到第一个题目
    }
  }, [displayedQuestions, currentQuestionIndex]);

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000); // 5000 毫秒 = 5 秒

      // 清理定时器
      return () => clearTimeout(timer);
    }
  }, [showAlert]);
  useEffect(() => {
    const filtered = questions.filter(
      (question) =>
        selectedTags.length === 0 ||
        selectedTags.some((tag) => question.tags.includes(tag))
    );

    setDisplayedQuestions(filtered); // 更新过滤后的题目
    setCurrentQuestionIndex(0); // 切换标签时重置到第一题
  }, [questions, selectedTags]);
  // 当 `selectedTags` 或 `questions` 改变时，更新过滤后的题目
  useEffect(() => {
    if (questions.length > 0) {
      const filtered =
        selectedTags.length === 0
          ? questions // 默认显示所有题目
          : questions.filter((question) =>
            selectedTags.some((tag) => question.tags.includes(tag))
          );

      setDisplayedQuestions(filtered);
      setCurrentPage(0); // 切换标签时重置到第一页
    }
  }, [questions, selectedTags]);
  // 解析 JWT 并检查是否有效（会员状态）
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const token = localStorage.getItem("token"); // 获取本地存储的 token（如果有）

        const headers = {
          "Content-Type": "application/json",
        };

        // 如果有 token，就附加 Authorization 头
        if (token) {
          headers["Authorization"] = `Bearer ${token}`;
        }

        // 发起请求
        const response = await fetch("/api/multiple_choice_questions", {
          method: "GET",
          headers: headers,
        });

        if (!response.ok) {
          console.error("Failed to fetch questions:", response.status, response.statusText);
          return;
        }

        const data = await response.json();

        // 排序：解锁题目在前，锁定题目在后
        const sortedQuestions = data.sort((a, b) => {
          if (!a.is_locked && b.is_locked) return -1; // 解锁优先
          if (a.is_locked && !b.is_locked) return 1;
          return 0;
        });

        setQuestions(sortedQuestions); // 设置全量题目
        setDisplayedQuestions(sortedQuestions); // 默认显示所有题目
        setUserAnswers(Array(sortedQuestions.length).fill(null)); // 初始化答案状态
      } catch (error) {
        console.error("Failed to fetch questions:", error);
      }
    };

    fetchQuestions();
  }, []);


  // 使用 useCallback 缓存函数，避免每次渲染时重新创建
  const handleAnswer = useCallback(
    (index, selectedOption) => {
      const newAnswers = [...userAnswers];
      newAnswers[index] = selectedOption;
      setUserAnswers(newAnswers);
    },
    [userAnswers]
  );

  const handleNext = useCallback(() => {
    setCurrentQuestionIndex((prevIndex) => {
      const nextIndex = prevIndex + 1;

      // 如果下一题被锁定并且用户不是会员，弹出购买提示
      if (displayedQuestions[nextIndex]?.is_locked) {
        setShowPurchaseDialog(true);
        return prevIndex; // 不切换
      }

      return Math.min(nextIndex, displayedQuestions.length - 1); // 正常切换
    });
  }, [displayedQuestions, setShowPurchaseDialog]);


  const handlePrevious = useCallback(() => {
    setCurrentQuestionIndex((prevIndex) => Math.max(prevIndex - 1, 0)); // 基于 displayedQuestions
  }, []);


  useEffect(() => {
    const handleKeyDown = (event) => {
      // 先关闭提示框
      setShowAlert(false);

      // 处理键盘事件
      if (event.key === "ArrowRight") {
        handleNext();
      } else if (event.key === "ArrowLeft") {
        handlePrevious();
      } else if (event.key >= "1" && event.key <= "4") {
        // 判断当前题目是否锁定
        if (displayedQuestions[currentQuestionIndex]?.is_locked) {
          setShowPurchaseDialog(true);
          return; // 锁定状态，阻止操作
        }
        handleAnswer(currentQuestionIndex, parseInt(event.key) - 1);
      }
    };

    // 添加事件监听
    window.addEventListener("keydown", handleKeyDown);

    // 清理事件监听
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentQuestionIndex, handleAnswer, handleNext, handlePrevious, displayedQuestions]);

  useEffect(() => {
    if (questions.length > 0) {
      const filtered = selectedTags.length === 0
        ? questions // 默认显示所有题目
        : questions.filter((q) => selectedTags.some((tag) => q.tags.includes(tag)));

      const sorted = [...filtered].sort((a, b) => {
        if (!a.is_locked && b.is_locked) return -1; // 解锁优先
        if (a.is_locked && !b.is_locked) return 1;
        return 0;
      });

      setDisplayedQuestions(sorted);
      setCurrentPage(0); // 切换标签时重置到第一页
    }
  }, [questions, selectedTags]);

  const tags = {
    Python基础知识: [
      "Python - 基础",
      "Python - 控制",
      "Python - 函数",
      "Python - 组合",
      "Python - 文件",
      "Python - 生态",
    ],
    二级公共基础: [
      "数据结构与算法",
      "程序设计基础",
      "软件工程基础",
      "数据库设计基础",
      "计算机系统",
    ],
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh", // 占满整个视口高度
        display: "flex",
        flexDirection: isMobile ? "column" : "row", // 在移动端只显示一列
        overflow: "hidden", // 避免内容溢出
      }}
    >
      <Helmet>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Python二级选择题练习平台，提供丰富的二级公共基础与Python基础知识的选择题真题，在线测试，助力备考"
        />
        <meta name="keywords" content="Python二级, 选择题, 编程题, Python基础, 公共基础, 真题练习" />
        <meta name="author" content="Python2ji Team" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Meta Tags for social sharing */}
        <meta property="og:title" content="Python二级选择题练习 - Python基础知识与二级公共基础真题" />
        <meta
          property="og:description"
          content="在线进行Python二级选择题的真题练习，涵盖Python基础知识与二级公共基础，提升备考效率"
        />
        <meta property="og:image" content="https://python2ji.com/logo.png" />

        <title>选择题 | Python二级在线</title>
      </Helmet>
      {/* 左侧内容区域 */}
      <Box
        sx={{
          flex: isMobile ? 1 : 5, // 在移动端占满整个容器
          display: "flex",
          flexDirection: "column",
          height: "100%", // 高度填满
        }}
      >
        <Paper
          elevation={3}
          sx={{
            flex: 1,
            padding: "32px", // 调整内边距
            display: "flex",
            flexDirection: "column",
            gap: "16px", // 统一间距
            borderRadius: "0px", // 无圆角
            height: "100%", // 确保填满父容器
          }}
        >
          {/* 提示框 */}
          {showAlert && (
            <Alert
              severity="info"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => setShowAlert(false)}
                >
                  我已知晓
                </Button>
              }
              sx={{
                borderRadius: 0,
                marginBottom: 2,
              }}
            >
              <AlertTitle>提示</AlertTitle>
              您可以使用 <strong>左右箭头键</strong> 切换题目，使用{" "}
              <strong>1、2、3、4</strong> 数字键选择选项 A、B、C、D。
            </Alert>
          )}

          {/* 标题 */}
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontSize: isMobile ? "1.2rem" : "1.5rem",
              fontWeight: "bold",
            }}
          >
            选择题
          </Typography>

          {/* 问题显示 */}
          <Box
            sx={{
              flex: 1,
              overflowY: "auto", // 可滚动
              border: "1px solid #d1d5db",
              borderRadius: "8px",
              padding: 2,
              backgroundColor: "#fafafa",
              maxHeight: "calc(100vh - 300px)", // 限制最大高度，确保按钮可见
            }}
          >
            <MultipleChoiceQuestion
              displayedQuestions={displayedQuestions} // 确保正确传递
              currentQuestionIndex={currentQuestionIndex}
              userAnswers={userAnswers}
              handleAnswer={handleAnswer}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              isMobile={isMobile}
              setShowPurchaseDialog={setShowPurchaseDialog}
            />



          </Box>

          {/* 按钮 */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "16px",
              gap: isMobile ? 2 : 0,
              flexShrink: 0, // 防止被压缩
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handlePrevious}
              disabled={currentQuestionIndex === 0}
              fullWidth={isMobile}
            >
              上一题
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleNext}
              fullWidth={isMobile}
            >
              下一题
            </Button>
          </Box>
        </Paper>
      </Box>

      {/* 右侧切换问题区域 - 在移动端隐藏 */}
      {!isMobile && (
        <Box
          sx={{
            flex: 0.9,
            padding: 0,
            display: "flex",
            flexDirection: "column", // 垂直布局
            gap: 2,
          }}
        >
          <Paper
            elevation={3}
            sx={{
              flex: 1,
              padding: "16px", // 可选内边距
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              borderRadius: 0, // 无圆角
              height: "100%", // 高度填满
              overflow: "hidden", // 避免内容溢出
            }}
          >
            <Box
              sx={{
                flex: 1, // 动态分配剩余空间
                maxHeight: "360px", // 限制高度
                overflowY: "hidden", // 超出部分滚动
              }}
            >
              <MultipleChoiceSwitcher
                currentQuestionIndex={currentQuestionIndex}
                setCurrentQuestionIndex={setCurrentQuestionIndex} // 确保名字一致
                displayedQuestions={displayedQuestions}
                userAnswers={userAnswers}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setShowPurchaseDialog={setShowPurchaseDialog}
              />

            </Box>
            {/* 标签过滤器 */}
            <Box
              sx={{
                flexShrink: 0, // 防止被压缩
                paddingTop: "8px",
              }}
            >
              <TagFilter
                tags={tags}
                selectedTags={selectedTags}
                setSelectedTags={(newTags) => {
                  setSelectedTags(newTags);
                  setCurrentPage(0); // 切换标签时回到第一页

                  // 清空答案：重置 userAnswers
                  const filteredQuestions =
                    newTags.length === 0
                      ? questions
                      : questions.filter((q) => newTags.some((tag) => q.tags.includes(tag)));

                  setDisplayedQuestions(filteredQuestions); // 更新过滤后的问题
                  setUserAnswers(Array(filteredQuestions.length).fill(null)); // 清空答案
                }}
              />

            </Box>

          </Paper>
        </Box>
      )}

      {/* 购买提示弹窗 */}
      <Dialog
        open={showPurchaseDialog}
        onClose={() => setShowPurchaseDialog(false)}
      >
        <DialogTitle>提示</DialogTitle>
        <DialogContent>
          您只能访问前 100 道题目。成为会员可以解锁更多内容！
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPurchaseDialog(false)}>取消</Button>
          <Button
            onClick={() => (window.location.href = "/upgrade")}
            color="primary"
            variant="contained"
          >
            立即购买
          </Button>
        </DialogActions>
      </Dialog>
    </Box>

  );

};

export default MultipleChoicePage;
