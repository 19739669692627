import React, { useEffect } from 'react';
import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const TurtleWindow = ({ isOpen, onClose }) => {
  useEffect(() => {
    // 确保 Brython 只初始化一次
    if (!window.brythonInitialized) {
      if (window.brython) {
        window.brython(); // 初始化 Brython
        window.brythonInitialized = true; // 设置标记，防止重复初始化
      }
    }

    // 每次窗口打开时，重置 turtle 的状态
    if (isOpen) {
      const turtleDiv = document.getElementById('turtle-div');
      if (turtleDiv) {
        // 清空 turtle-div 的内容，避免继承上次的绘图状态
        turtleDiv.innerHTML = '';
      }
    }
  }, [isOpen]);

  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: '800px',
        maxHeight: '90%',
        backgroundColor: '#f9f9fc', // MUI recommended light background
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)', // Stronger shadow for emphasis
        zIndex: isOpen ? 9999 : -1,
        display: isOpen ? 'block' : 'none',
        overflow: 'hidden',
      }}
    >
      {/* 标题栏 */}
      <div
        style={{
          backgroundColor: '#1976d2', // MUI primary color
          padding: '10px 16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #ccc',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              fontWeight: 'bold',
              fontSize: '16px',
              color: '#fff', // White text for better contrast
            }}
          >
            Turtle 执行状态
          </span>
          {/* Tooltip with question mark icon and explanation text */}
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px', color: '#fff' }}>
            <Tooltip title="提示信息">
              <InfoOutlinedIcon fontSize="small" style={{ marginRight: '5px' }} />
            </Tooltip>
            <span style={{ fontSize: '14px' }}>
              请保证turtle代码以 <code>t.done()</code> 或 <code>turtle.done()</code> 结束
            </span>
          </div>
        </div>
        <button
          onClick={onClose}
          style={{
            background: 'none',
            border: 'none',
            fontSize: '16px',
            cursor: 'pointer',
            color: '#fff',
          }}
        >
          ✖
        </button>
      </div>

      {/* turtle-div 内容区域 */}
      <div
        id="turtle-div"
        style={{
          width: '100%',
          height: '500px',
          backgroundColor: '#e3f2fd', // MUI light blue background
          borderRadius: '0 0 8px 8px',
        }}
      >
        {/* Turtle 绘图内容将显示在这里 */}
      </div>
    </div>
  );
};

export default TurtleWindow;
