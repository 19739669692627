import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CodeIcon from '@mui/icons-material/Code';
import HelpIcon from '@mui/icons-material/Help'; // 引入帮助图标
import Brightness4Icon from '@mui/icons-material/Brightness4';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


const NavBar = ({ isDarkMode, toggleTheme }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // 判断是否为移动设备

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const menuItems = [
    { text: '首页', icon: <HomeIcon />, path: '/' },
    { text: '选择题', icon: <ListAltIcon />, path: '/multiple-choice' },
    { text: '程序题', icon: <CodeIcon />, path: '/programming' },
    { text: '成为会员', icon: <PersonAddIcon />, path: '/upgrade' },
    { text: '帮助', icon: <HelpIcon />, path: '/help' },  // 添加帮助页面
  ];

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ width: 250 }}>
      <List>
        {menuItems.map((item) => (
          <ListItem button key={item.text} onClick={() => navigate(item.path)}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
        <ListItem button onClick={toggleTheme}>
          <ListItemIcon>
            {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
          </ListItemIcon>
          <ListItemText primary={isDarkMode ? '日间模式' : '夜间模式'} />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar position="static">
      <Toolbar>
        {isMobile && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <img
          src="/icon_192x192.ico"
          alt="网站 Logo"
          style={{ height: '50px', marginRight: '2px' }}
        />
        <Typography
          variant="h6"
          sx={{
            flexGrow: 1,
            display: { xs: 'block', sm: 'block' }, // 在小屏幕上隐藏标题
          }}
        >
          Python 二级在线
        </Typography>
        {!isMobile && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              color="inherit"
              startIcon={isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
              onClick={toggleTheme}
              sx={{ mr: 2 }}
            >
              {isDarkMode ? '日间模式' : '夜间模式'}
            </Button>
            {menuItems.map((item) => (
              <Button
                key={item.text}
                color="inherit"
                startIcon={item.icon}
                onClick={() => navigate(item.path)}
                sx={{
                  mx: 1,
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  },
                  '&.Mui-selected': {
                    backgroundColor: 'rgba(255, 255, 255, 0.4)',
                  },
                }}
              >
                {item.text}
              </Button>
            ))}
          </Box>
        )}
      </Toolbar>
      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
        {drawer}
      </Drawer>
    </AppBar>
  );
};

export default NavBar;
