import React from "react";
import { Box, Chip, Typography, IconButton } from "@mui/material";
import SelectAllIcon from "@mui/icons-material/SelectAll"; // 引入全选图标

const TagFilter = ({ tags, selectedTags, setSelectedTags, resetToFirstPage }) => {
  const handleTagClick = (tag, isCategory) => {
    let updatedSelectedTags;

    if (isCategory) {
      const categoryTags = tags[tag];
      const allSelected = categoryTags.every((t) => selectedTags.includes(t));
      if (allSelected) {
        updatedSelectedTags = selectedTags.filter((t) => !categoryTags.includes(t));
      } else {
        updatedSelectedTags = [...new Set([...selectedTags, ...categoryTags])];
      }
    } else {
      if (selectedTags.includes(tag)) {
        updatedSelectedTags = selectedTags.filter((t) => t !== tag);
      } else {
        updatedSelectedTags = [...selectedTags, tag];
      }
    }

    // 更新选中标签状态
    setSelectedTags(updatedSelectedTags);

    // **重置到第一页**
    if (resetToFirstPage) resetToFirstPage();
  };

  const getColor = (category) => {
    switch (category) {
      case "Python基础知识":
        return "primary";
      case "二级公共基础":
        return "warning";
      default:
        return "default";
    }
  };

  return (
    <Box sx={{ mb: 2 }}>
      {Object.keys(tags).map((category) => {
        const categoryTags = tags[category];
        const selectedCount = categoryTags.filter((tag) =>
          selectedTags.includes(tag)
        ).length;

        return (
          <Box key={category} sx={{ mb: 2 }}>
            {/* 分类标题和全选按钮 */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="subtitle1" gutterBottom>
                {`${category} (${selectedCount}/${categoryTags.length})`}
              </Typography>
              <IconButton
                size="small"
                onClick={() => handleTagClick(category, true)}
                color={
                  selectedCount === categoryTags.length
                    ? getColor(category)
                    : "default"
                }
                sx={{ ml: 1 }}
              >
                <SelectAllIcon />
              </IconButton>
            </Box>

            {/* 标签列表 */}
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {categoryTags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  onClick={() => handleTagClick(tag, false)}
                  color={selectedTags.includes(tag) ? getColor(category) : "default"}
                  sx={{
                    m: 0.5,
                    minWidth: "100px", // 更紧凑的宽度
                    justifyContent: "center",
                    height: "32px", // 更紧凑的高度
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    borderRadius: "6px",
                    fontSize: "0.8rem", // 略小的字体
                    padding: "2px 8px", // 更紧凑的内部填充
                  }}
                />
              ))}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default TagFilter;
